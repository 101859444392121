import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/Button';
import TimePicker from '@rio-cloud/rio-uikit/TimePicker';
import Notification from '@rio-cloud/rio-uikit/Notification';
import Configuration from '../Configuration';
import NextOccurrences from './NextOccurrences';
import { VehicleDetailsContext } from '../../../../../../../../providers/contexts';
import { Timer } from '../vehicleChargingConfigurationTypes';
import { WeekdayButtons } from './WeekdayButtons';
import { currentTimezone } from '../../../../../../utils/timezone';
import { convertDepartureTime } from '../../vehicleDetailsHelper';

interface TimerSetupFormProps {
    assetId: string;
    timer?: Timer;
    onSave: (timer: Timer) => void;
    onCloseForm: () => void;
}

const TimerSetupForm = ({ timer, onSave, onCloseForm }: TimerSetupFormProps) => {
    const intl = useIntl();

    const initialConfiguration = useMemo(
        () => ({
            weekdays: timer?.weekdays ?? [],
            departureTime: timer
                ? convertDepartureTime(`${timer.departureTimeHour}:${timer.departureTimeMinute}`, timer.zoneId)
                : '12:00',
            zoneId: timer?.zoneId ?? '',
            climateMode: timer?.climateMode ?? false,
            chargeUntil: timer?.chargeUntil ?? 100,
            readyToDriveDuration: timer?.readyToDriveDuration ?? 30,
            weeklyRepeat: timer?.weeklyRepeat ?? true,
            enabled: timer?.enabled ?? true,
        }),
        [timer]
    );

    const [timerConfig, setTimerConfig] = useState(initialConfiguration);

    const { hasUnsavedChanges, setHasUnsavedChanges, changedChargingTab } = useContext(VehicleDetailsContext);

    // Set the unsaved changes flag when the configuration values are modified
    useEffect(() => {
        const hasChanges = Object.entries(timerConfig).some(
            ([key, value]) => value !== initialConfiguration[key as keyof typeof initialConfiguration]
        );
        setHasUnsavedChanges(hasChanges);
    }, [timerConfig, initialConfiguration, setHasUnsavedChanges]);

    const handleChange = useCallback(
        (key: keyof typeof timerConfig) => (value: any) => {
            setTimerConfig(prevConfig => ({ ...prevConfig, [key]: value }));
        },
        [setTimerConfig]
    );

    // Clears the selected weekdays if weeklyRepeat is off and more than one weekday is selected
    useEffect(() => {
        if (!timerConfig.weeklyRepeat && timerConfig.weekdays.length > 1) {
            handleChange('weekdays')([]);
        }
    }, [timerConfig.weeklyRepeat, timerConfig.weekdays, handleChange]);

    const hasWeekdaysSelected = timerConfig.weekdays.length !== 0;

    const handleSaveTimer = () => {
        if (!hasWeekdaysSelected) {
            Notification.error(
                intl.formatMessage({ id: 'e4c.vehicle.details.error.invalidTimer.description' }),
                intl.formatMessage({ id: 'e4c.vehicle.details.error.invalidTimer.title' }),
                3000
            );
            return;
        }

        const [hour, minute] = timerConfig.departureTime.split(':');
        const newTimer: Timer = {
            ...timerConfig,
            id: timer?.id ?? '',
            departureTimeHour: parseInt(hour, 10),
            departureTimeMinute: parseInt(minute, 10),
            zoneId: currentTimezone,
            nextDeparturesTimeStatus: [],
        };

        onSave(newTimer);
    };

    return (
        <div className="border padding-y-10 padding-x-25 position-relative" data-testid="timer-setup-form">
            <Button
                bsStyle={Button.MUTED}
                bsSize={Button.XS}
                iconName="rioglyph rioglyph-remove"
                iconOnly
                className="position-absolute top-10 right-10"
                onClick={() => onCloseForm()}
                label="close-button"
            />
            <div className="text-medium">{intl.formatMessage({ id: 'e4c.vehicle.details.weekDays' })}</div>
            <div className="display-flex justify-content-between margin-top-10 margin-bottom-20" data-testid="weekdays">
                <WeekdayButtons
                    weekdays={timerConfig.weekdays}
                    setWeekdays={handleChange('weekdays')}
                    isRepeatTimer={timerConfig.weeklyRepeat}
                />
                <TimePicker
                    value={timerConfig.departureTime}
                    onChange={handleChange('departureTime')}
                    showIcon
                    className="width-25pct"
                />
            </div>
            <Configuration
                title={intl.formatMessage({ id: 'e4c.vehicle.details.repeatTimer.title' })}
                description={
                    timerConfig.weeklyRepeat
                        ? intl.formatMessage({ id: 'e4c.vehicle.details.repeatTimer.description.on' })
                        : intl.formatMessage({ id: 'e4c.vehicle.details.repeatTimer.description.off' })
                }
                toggle={{
                    value: timerConfig.weeklyRepeat,
                    setValue: handleChange('weeklyRepeat'),
                }}
            />
            {hasWeekdaysSelected && (
                <NextOccurrences
                    weekdays={timerConfig.weekdays}
                    departureTime={timerConfig.departureTime}
                    repeatTimer={timerConfig.weeklyRepeat}
                />
            )}
            <hr />
            <Configuration
                title={intl.formatMessage({ id: 'e4c.vehicle.details.chargeUntil.title' })}
                description={intl.formatMessage({ id: 'e4c.vehicle.details.chargeUntil.description' })}
                slider={{
                    value: timerConfig.chargeUntil,
                    minValue: 20,
                    maxValue: 100,
                    step: 1,
                    unit: '%',
                    setValue: handleChange('chargeUntil'),
                }}
            />
            <hr />
            <Configuration
                title={intl.formatMessage({ id: 'e4c.vehicle.details.climateControl.title' })}
                description={intl.formatMessage({ id: 'e4c.vehicle.details.climateControl.description.timer' })}
                toggle={{
                    value: timerConfig.climateMode,
                    setValue: handleChange('climateMode'),
                }}
            />
            <hr />
            <Configuration
                title={intl.formatMessage({ id: 'e4c.vehicle.details.readyToDrive.title' })}
                description={intl.formatMessage({ id: 'e4c.vehicle.details.readyToDrive.description' })}
                slider={{
                    value: timerConfig.readyToDriveDuration,
                    minValue: 0,
                    maxValue: 180,
                    step: 5,
                    unit: intl.formatMessage({ id: 'e4c.global.minutes.unit' }),
                    setValue: handleChange('readyToDriveDuration'),
                }}
            />
            <div className="display-flex justify-content-end margin-top-25" data-testid="save-timer">
                <Button
                    bsStyle={Button.PRIMARY}
                    iconName="rioglyph rioglyph-time-alt"
                    onClick={handleSaveTimer}
                    disabled={!(hasUnsavedChanges || changedChargingTab)}
                >
                    {intl.formatMessage({ id: 'e4c.vehicle.details.saveTimer.button' })}
                </Button>
            </div>
        </div>
    );
};

export default TimerSetupForm;
