import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { ProductVersion } from '../../../../../common/vehicles/productVersion';
import LabelledText from '../../../../../common/components/labelledText/LabelledText';
import { hasStateOfChargeAvailable } from '../../../../helpers/rulesVehicleCharging';
import { VehicleChargingData } from '../../../../models/VehicleChargingData';
import { MemoBatteryStatusColumn } from '../../../table/columns/BatteryStatusColumn/BatteryStatusColumn';
import AssetDetailsChargingState from './AssetDetailsChargingState';
import { formatLastUpdated, hasTextInformation } from './AssetDetailsInfoHelper';
import VehicleIdentification from '../../../../../common/components/Tables/VehicleIdentification/VehicleIdentification';
import { useTimezone } from '../../../../../common/providers/timezone';

const AssetDetails = ({ vehicleData }: { vehicleData: VehicleChargingData }): ReactElement => {
    const intl = useIntl();
    const timezone = useTimezone();
    const stateOfCharge = vehicleData.measurements.stateOfCharge.value;
    const getRemainingRange = () => {
        const remainingRangeValue = vehicleData.measurements.remainingRange.value;
        return remainingRangeValue ? Math.round(remainingRangeValue) : remainingRangeValue;
    };

    return (
        <>
            <div className="display-flex margin-x-20 margin-bottom-20" data-testid="asset-details">
                <div>
                    <VehicleIdentification
                        name={vehicleData.assetInfo.name}
                        productVersion={vehicleData.productVersion}
                        vehicleType={vehicleData.assetInfo.type}
                    />
                    <div className="display-flex justify-content-between margin-top-10 margin-left-5">
                        <div className="display-flex align-items-center">
                            <LabelledText label="VIN" text={vehicleData.assetInfo.vin} />
                        </div>
                        <div className="display-flex align-items-center margin-left-10">
                            <LabelledText
                                label={intl.formatMessage({ id: 'e4c.vehicles.timestamp' })}
                                text={formatLastUpdated(vehicleData.measurementsLastUpdated, intl.locale, timezone)}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 align-self-center">
                    <div className="display-flex justify-content-end">
                        <div className="margin-right-20">
                            <AssetDetailsChargingState vehicleData={vehicleData} />
                        </div>
                        <MemoBatteryStatusColumn
                            isDisabled={!hasStateOfChargeAvailable(stateOfCharge)}
                            value={stateOfCharge}
                        />
                    </div>
                </div>
            </div>
            <hr className="margin-0" />
            <div className="display-flex margin-x-20 margin-y-15" style={{ columnGap: '50px' }}>
                <div className="display-flex flex-column">
                    <LabelledText
                        text={hasTextInformation(getRemainingRange(), ' Km')}
                        label={intl.formatMessage({ id: 'e4c.sidebar.remainingRange' })}
                    />
                </div>
                {vehicleData.productVersion === ProductVersion.EMANAGER_M ? (
                    <>
                        <div className="display-flex flex-column">
                            <LabelledText
                                text={hasTextInformation(vehicleData.measurements.ambientTemperature.value, 'ºC')}
                                label={intl.formatMessage({ id: 'e4c.history.outsideTemperature' })}
                            />
                        </div>
                        <div className="display-flex flex-column">
                            <LabelledText
                                text={hasTextInformation(vehicleData.measurements.insideTemperature.value, 'ºC')}
                                label={intl.formatMessage({ id: 'e4c.sidebar.insideTemperature' })}
                            />
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
};

export default AssetDetails;
