import { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { VehicleDetailsContext } from '../../../../../../../providers/contexts';
import { usePatchTimerConfigurations, usePostImmediateConfigurations } from '../../../../queries/useVehicleChargingAPI';
import { State } from '../../../../../../../types';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../../../../../../../configuration';
import { cloneDeep } from 'lodash';
import { useQueryClient } from 'react-query';

interface SaveChargingSettingsProps {
    chargingTab: string;
    assetId: string;
    isReadOnly: boolean;
}

const SaveChargingSettings = ({ chargingTab, assetId, isReadOnly }: SaveChargingSettingsProps) => {
    const accessToken = useSelector((state: State) => getAccessToken(state));

    const {
        hasUnsavedChanges,
        setHasUnsavedChanges,
        changedChargingTab,
        setSendingChargingConfigFailed,
        setSendingChargingConfigSucceeded,
        immediateChargingChanges,
        timers,
        setTimers,
        unsavedTimers,
    } = useContext(VehicleDetailsContext);

    const { isError, isSuccess, mutate } = usePostImmediateConfigurations(
        accessToken,
        assetId,
        immediateChargingChanges
    );

    const { mutate: mutateTimers } = usePatchTimerConfigurations(accessToken, assetId, timers, unsavedTimers);

    const queryClient = useQueryClient();

    const isSaveEnabled = () => {
        if (isReadOnly) {
            return false;
        }
        if (hasUnsavedChanges) {
            return true;
        }
        if (changedChargingTab) {
            if (chargingTab === 'timerTab') {
                return unsavedTimers.some(timer => timer.enabled);
            }
            return true;
        }
        return false;
    };

    // Set the notifications according to the Save action result
    useEffect(() => {
        setSendingChargingConfigSucceeded(isSuccess);
        setSendingChargingConfigFailed(isError);

        if (isSuccess) {
            setHasUnsavedChanges(false);
        }
    }, [isSuccess, isError, setSendingChargingConfigSucceeded, setSendingChargingConfigFailed, setHasUnsavedChanges]);

    const saveTimers = () => {
        mutateTimers(undefined, {
            onSuccess: () => {
                setHasUnsavedChanges(false);
                setTimers(cloneDeep(unsavedTimers));
                setSendingChargingConfigSucceeded(true);
                setSendingChargingConfigFailed(false);
                queryClient.invalidateQueries(`vehicle-charging-${assetId}`);
            },
            onError: () => {
                setSendingChargingConfigSucceeded(false);
                setSendingChargingConfigFailed(true);
            },
        });
    };
    return (
        <button
            className="btn btn-primary display-flex margin-left-auto margin-top-20"
            type="button"
            id="save-button"
            disabled={!isSaveEnabled()}
            onClick={() => (chargingTab === 'timerTab' ? saveTimers() : mutate())}
        >
            <FormattedMessage id="e4c.global.save" />
        </button>
    );
};

export default SaveChargingSettings;
