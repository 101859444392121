import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';
import AssetDetails from '../VehicleDetailsInfo/AssetDetailsInfo/AssetDetails';
import { VehicleChargingData } from '../../../models/VehicleChargingData';
import notVanishingNotificationError from './notVanishingNotificationError';
import { useContext, useEffect } from 'react';
import { VehicleDetailsContext } from '../../../../../../providers/contexts';
import VehicleDetailsProvider from '../../../../../../providers/VehicleDetailsProvider';
import { ProductVersion } from '../../../../common/vehicles/productVersion';
import ProgrammedConfiguration from '../VehicleDetailsConfiguration/ProgrammedConfiguration/ProgrammedConfiguration';
import VehicleTypeClass from '../../../mappers/vehicleIcons/VehicleTypeClass';
import UpsellStateWrapper from '../../../../common/components/upsell/UpsellStateWrapper';
import VehicleModel from '../../../models/enums/VehicleModel';
import ChargingMode from './ChargingMode/ChargingMode';
import { UpcomingFeatureState } from './UpcomingFeatureState';

interface VehicleDetailsSidebarProps {
    vehicle: VehicleChargingData;
    onCloseSidebar: () => void;
    onToggleConfirmDialog: (show: boolean) => void;
    onToggleUnsavedVehicleChanges: (show: boolean) => void;
}

export const VehicleDetailsSidebar = ({
    vehicle,
    onCloseSidebar,
    onToggleConfirmDialog,
    onToggleUnsavedVehicleChanges,
}: VehicleDetailsSidebarProps) => {
    const {
        hasUnsavedChanges,
        sendingChargingConfigFailed,
        sendingChargingConfigSucceeded,
        setSendingChargingConfigSucceeded,
        changedChargingTab,
        resetContext,
    } = useContext(VehicleDetailsContext);

    const handleCloseSidebar = (): void => {
        if (hasUnsavedChanges || changedChargingTab) {
            onToggleConfirmDialog(true);
        } else {
            onCloseSidebar();
            resetContext();
        }
    };

    // Notification handling for save success/failure
    useEffect(() => {
        if (sendingChargingConfigFailed) {
            notVanishingNotificationError(<FormattedMessage id="e4c.vehicle.details.save.failure" />);
        }

        if (sendingChargingConfigSucceeded) {
            Notification.success(<FormattedMessage id="e4c.vehicle.details.save.success" />, '', 3000);
            setSendingChargingConfigSucceeded(false);
        }
    }, [sendingChargingConfigFailed, sendingChargingConfigSucceeded, setSendingChargingConfigSucceeded]);

    // Show pending changes pop up when selecting other vehicle
    useEffect(() => {
        if (hasUnsavedChanges || changedChargingTab) {
            onToggleUnsavedVehicleChanges(true);
        } else {
            onToggleUnsavedVehicleChanges(false);
        }
    }, [hasUnsavedChanges, changedChargingTab, onToggleUnsavedVehicleChanges]);

    const renderChargingDetails = () => {
        const { vehicleModel } = vehicle.assetInfo;
        const { productVersion, assetId } = vehicle;

        if ([VehicleModel.ELSA, VehicleModel.TRUE].includes(vehicleModel)) {
            return <UpcomingFeatureState />;
        }

        if (productVersion === ProductVersion.EMANAGER_M) {
            return vehicleModel === VehicleModel.ETGE ? (
                <ProgrammedConfiguration typeClass={VehicleTypeClass.VAN} />
            ) : (
                <ChargingMode assetId={assetId} />
            );
        }

        return (
            <div className="margin-20">
                <UpsellStateWrapper
                    features={[
                        <FormattedMessage key="a" id="e4v.upselling.drivingStatus" />,
                        <FormattedMessage key="b" id="e4v.upselling.remainingRangeInKwH" />,
                    ]}
                />
            </div>
        );
    };

    return (
        <Sidebar
            title={<FormattedMessage id="e4c.sidebar.title" />}
            titleClassName="padding-left-5"
            headerClassName="min-height-0"
            closed={false}
            onClose={handleCloseSidebar}
            position="right"
            resizable={false}
            width={615}
            fly
        >
            {vehicle && <AssetDetails vehicleData={vehicle} />}
            <hr className="margin-0" />
            {renderChargingDetails()}
        </Sidebar>
    );
};

const NewVehicleDetailsSidebar = ({
    vehicle,
    onCloseSidebar,
    onToggleConfirmDialog,
    onToggleUnsavedVehicleChanges,
}: VehicleDetailsSidebarProps) => (
    <VehicleDetailsProvider>
        <VehicleDetailsSidebar
            vehicle={vehicle}
            onCloseSidebar={onCloseSidebar}
            onToggleConfirmDialog={onToggleConfirmDialog}
            onToggleUnsavedVehicleChanges={onToggleUnsavedVehicleChanges}
        />
    </VehicleDetailsProvider>
);

export default NewVehicleDetailsSidebar;
