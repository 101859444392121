import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/Button';
import { Timer } from '../vehicleChargingConfigurationTypes';
import TimerSetupForm from './TimerSetupForm';
import { usePostTimerConfigurations } from '../../../../../queries/useVehicleChargingAPI';
import { State } from '../../../../../../../../types';
import { getAccessToken } from '../../../../../../../../configuration';
import { VehicleDetailsContext } from '../../../../../../../../providers/contexts';
import TimerListView from './TimerListView';
import { cloneDeep } from 'lodash';

interface TimerChargingProps {
    assetId: string;
    timers?: Timer[];
    isReadOnly: boolean;
}

const TimerCharging = ({ assetId, timers, isReadOnly }: TimerChargingProps) => {
    const intl = useIntl();
    const [timerList, setTimerList] = useState<Timer[] | []>(timers ?? []);
    const [selectedTimer] = useState<Timer | undefined>(undefined); // TODO: timer selected to be edited
    const [showSetupForm, setShowSetupForm] = useState(false);

    const accessToken = useSelector((state: State) => getAccessToken(state));
    const { mutate } = usePostTimerConfigurations(accessToken, assetId);

    const {
        setHasUnsavedChanges,
        setSendingChargingConfigFailed,
        setSendingChargingConfigSucceeded,
        setTimers,
        setUnsavedTimers,
    } = useContext(VehicleDetailsContext);

    useEffect(() => {
        setTimers(cloneDeep(timers) || []);
        setUnsavedTimers(cloneDeep(timers) || []);
    }, [setUnsavedTimers, setTimers, timers]);

    const handleSave = (updatedTimer: Timer) => {
        const updatedTimers = selectedTimer
            ? timerList.map(timer => (timer === selectedTimer ? updatedTimer : timer))
            : [...timerList, updatedTimer];

        mutate(updatedTimers, {
            onSuccess: () => {
                setTimerList(updatedTimers);
                setHasUnsavedChanges(false);
                setShowSetupForm(false);
                setSendingChargingConfigSucceeded(true);
            },
            onError: () => {
                setSendingChargingConfigFailed(true);
            },
        });
    };

    const hasNoTimers = !timers?.length;

    return (
        <>
            {hasNoTimers && (
                <>
                    <div className="display-flex">
                        <span className="rioglyph rioglyph-time text-color-light text-size-h1" />
                        <div className="display-flex flex-column margin-left-20">
                            <span className="text-medium">
                                {intl.formatMessage({ id: 'e4c.vehicle.details.timerCharging.noTimers.title' })}
                            </span>
                            {!isReadOnly && (
                                <span className="text-color-dark">
                                    {intl.formatMessage({
                                        id: showSetupForm
                                            ? 'e4c.vehicle.details.timerCharging.noTimers.description.form'
                                            : 'e4c.vehicle.details.timerCharging.noTimers.description',
                                    })}
                                </span>
                            )}
                        </div>
                    </div>
                    {!isReadOnly && !showSetupForm && (
                        <div className="margin-top-20" data-testid="add-new-timer">
                            <Button
                                bsStyle={Button.PRIMARY}
                                iconName="rioglyph-plus"
                                className="width-100pct justify-content-start"
                                onClick={() => setShowSetupForm(true)}
                            >
                                {intl.formatMessage({ id: 'e4c.vehicle.details.timerCharging.addTimer.button' })}
                            </Button>
                        </div>
                    )}
                </>
            )}
            {showSetupForm && (
                <div className="margin-top-20">
                    <TimerSetupForm
                        assetId={assetId}
                        timer={selectedTimer}
                        onSave={handleSave}
                        onCloseForm={() => setShowSetupForm(false)}
                    />
                </div>
            )}
            {!hasNoTimers && !showSetupForm && (
                <TimerListView addTimerAction={() => setShowSetupForm(true)} isReadOnly={isReadOnly} />
            )}
        </>
    );
};

export default TimerCharging;
