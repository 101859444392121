import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Configuration from './Configuration';
import { ImmediateConfiguration } from './vehicleChargingConfigurationTypes';
import { VehicleDetailsContext } from '../../../../../../../providers/contexts';

interface ImmediateChargingProps {
    configuration?: ImmediateConfiguration;
    isReadOnly: boolean;
}

const ImmediateCharging = ({ configuration, isReadOnly }: ImmediateChargingProps) => {
    const intl = useIntl();

    const initialChargeUntil = configuration?.chargeUntil ?? 100;
    const initialClimateMode = configuration?.climateMode ?? false;

    const [chargeUntil, setChargeUntil] = useState(initialChargeUntil);
    const [climateMode, setClimateMode] = useState(initialClimateMode);

    const { setHasUnsavedChanges, setImmediateChargingChanges } = useContext(VehicleDetailsContext);

    // Update state when the configuration prop changes
    useEffect(() => {
        if (configuration) {
            const { chargeUntil: newChargeUntil, climateMode: newClimateMode } = configuration;
            setChargeUntil(newChargeUntil ?? 100);
            setClimateMode(newClimateMode ?? false);
        }
    }, [configuration]);

    // Set the Immediate charging changes and unsaved changes flag when the configuration values are modified
    useEffect(() => {
        setImmediateChargingChanges({ chargeUntil, climateMode });
        setHasUnsavedChanges(chargeUntil !== initialChargeUntil || climateMode !== initialClimateMode);
    }, [
        chargeUntil,
        climateMode,
        initialChargeUntil,
        initialClimateMode,
        setHasUnsavedChanges,
        setImmediateChargingChanges,
    ]);

    return (
        <>
            <Configuration
                title={intl.formatMessage({ id: 'e4c.vehicle.details.chargeUntil.title' })}
                description={intl.formatMessage({ id: 'e4c.vehicle.details.chargeUntil.description' })}
                slider={{
                    value: chargeUntil,
                    minValue: 20,
                    maxValue: 100,
                    step: 1,
                    unit: '%',
                    setValue: setChargeUntil,
                    isDisabled: isReadOnly,
                }}
            />
            <hr />
            <Configuration
                title={intl.formatMessage({ id: 'e4c.vehicle.details.climateControl.title' })}
                description={intl.formatMessage({ id: 'e4c.vehicle.details.climateControl.description' })}
                toggle={{
                    value: climateMode,
                    setValue: setClimateMode,
                    isDisabled: isReadOnly,
                }}
                warningTag={{
                    tag: intl.formatMessage({ id: 'e4c.vehicle.details.notRecommended.tag' }),
                    tooltip: intl.formatMessage({ id: 'e4c.vehicle.details.notRecommended.tooltip' }),
                }}
            />
        </>
    );
};

export default ImmediateCharging;
