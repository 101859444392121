import { Timer } from '../vehicleChargingConfigurationTypes';
import { FormattedMessage } from 'react-intl';
import TimerView from './TimerView';
import Button from '@rio-cloud/rio-uikit/Button';
import { useContext } from 'react';
import { VehicleDetailsContext } from '../../../../../../../../providers/contexts';

interface TimerListViewProps {
    addTimerAction: Function;
    isReadOnly: boolean;
}

const TimerListView = ({ addTimerAction, isReadOnly }: TimerListViewProps) => {
    const { unsavedTimers } = useContext(VehicleDetailsContext);
    return (
        <>
            <div className="display-flex">
                <div className="flex-basis-70pct">
                    <div className="text-medium">
                        <FormattedMessage id="e4c.vehicle.details.timerCharging.addTimer.title" />
                    </div>
                    <div className="text-color-dark">
                        <FormattedMessage id="e4c.vehicle.details.timerCharging.addTimer.description" />
                    </div>
                </div>
            </div>
            {!isReadOnly && (
                <div className="display-flex">
                    <div className="flex-basis-70pct" />
                    <div className="flex-basis-30pct">
                        <Button
                            bsStyle={Button.PRIMARY}
                            variant={Button.VARIANT_LINK}
                            onClick={() => addTimerAction()}
                            data-testid="timer-list-add-new-timer"
                        >
                            <span className="rioglyph rioglyph-plus-light" />
                            <FormattedMessage id="e4c.vehicle.details.timerCharging.addTimer.button" />
                        </Button>
                    </div>
                </div>
            )}
            {unsavedTimers?.map((timer: Timer, index) => <TimerView key={index} timer={timer} />)}
        </>
    );
};

export default TimerListView;
