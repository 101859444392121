import { Timer, Weekday } from '../vehicleChargingConfigurationTypes';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChargingConfigurationStatusEnum } from '../../../../../types';
import { convertDepartureTime } from '../../vehicleDetailsHelper';
import NextOccurrences from './NextOccurrences';
import { mapShortWeekdayToIntl } from '../../../../../../common/enums/intl/weekdays';
import { Switch } from '@rio-cloud/rio-uikit';
import { useContext } from 'react';
import { VehicleDetailsContext } from '../../../../../../../../providers/contexts';

interface TimerViewProps {
    timer: Timer;
}

const TimerView = ({ timer }: TimerViewProps) => {
    const { updateTimerEnabledStatus } = useContext(VehicleDetailsContext);
    const intl = useIntl();
    const getDays = () =>
        timer.weekdays
            .map((day: Weekday) => intl.formatMessage({ id: mapShortWeekdayToIntl(day) }))
            .join(' | ')
            .toLowerCase();
    const hasRejectedStatus = timer.nextDeparturesTimeStatus.some(
        elem => elem.status === ChargingConfigurationStatusEnum.REJECTED
    );
    const formattedDepartureTime = convertDepartureTime(
        `${timer.departureTimeHour}:${timer.departureTimeMinute}`,
        timer.zoneId
    );
    return (
        <div
            className={`${
                hasRejectedStatus
                    ? 'border-color-danger callout callout-danger'
                    : 'padding-left-25 padding-right-20 padding-top-20 padding-bottom-20'
            } border margin-top-20`}
            data-testid="timer"
        >
            <div className="margin-10">
                <div className="margin-bottom-10">
                    <div className="display-flex justify-content-between">
                        <div>
                            <div className="display-flex">
                                <span className="text-bold text-capitalize">{getDays()}</span>
                                <span className="margin-left-10">{formattedDepartureTime}</span>
                            </div>
                            {timer.weeklyRepeat && (
                                <div className="text-size-12">
                                    <FormattedMessage id="e4c.vehicle.details.weeklyRepeat" />
                                </div>
                            )}
                        </div>
                        <div className="display-flex align-items-center">
                            <button
                                className="btn btn-action text-color-black text-size-18 rioglyph-pencil"
                                onClick={() => console.log(timer)}
                            />
                            <button
                                className="btn btn-action text-color-black text-size-18 rioglyph-trash margin-right-10"
                                onClick={() => {}}
                            />
                            <Switch
                                onChange={() => {
                                    updateTimerEnabledStatus(timer.id, !timer.enabled);
                                }}
                                checked={timer.enabled}
                            />
                        </div>
                    </div>
                </div>
                <div className="display-flex">
                    <span>
                        <FormattedMessage id="e4c.vehicle.details.chargeUntil.title" /> {timer.chargeUntil}%
                    </span>
                    <span className="margin-left-10 margin-right-10">|</span>
                    <span>
                        <FormattedMessage id="e4c.vehicle.details.preconditioning" />
                        {timer.climateMode ? ' ON' : ' OFF'}
                    </span>
                </div>
                <NextOccurrences
                    weekdays={timer.weekdays}
                    departureTime={formattedDepartureTime}
                    repeatTimer={timer.weeklyRepeat}
                    showStatus={true}
                    timersSentToVehicle={timer.nextDeparturesTimeStatus}
                />
            </div>
        </div>
    );
};

export default TimerView;
