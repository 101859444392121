import { ErrorCodeEnum } from '../../../../common/enums/ErrorCode';
import ErrorState from '../../../../common/components/states/ErrorState';
import { FormattedMessage } from 'react-intl';
import { ForbiddenStateProps, GeneralStateProps, NotFoundStateProps } from '../../../../common/components/states/types';

interface VehiclesDetailsErrorProps {
    errorCode: ErrorCodeEnum;
}

const VehiclesDetailsError = ({ errorCode }: VehiclesDetailsErrorProps): JSX.Element => {
    const vehicleStateProps: Record<string, NotFoundStateProps | GeneralStateProps | ForbiddenStateProps> = {
        [ErrorCodeEnum.OTHER]: { type: 'general' },
        [ErrorCodeEnum.FORBIDDEN]: { type: 'forbidden' },
        [ErrorCodeEnum.NOT_FOUND]: {
            type: 'notfound',
            message: <FormattedMessage id={'e4c.vehicles.notFoundState.sidebar.message'} />,
        },
    };

    return <ErrorState stateProps={vehicleStateProps[errorCode]} />;
};

export default VehiclesDetailsError;
