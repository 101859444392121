import { PropsWithChildren, useState } from 'react';
import { VehicleDetailsContext } from './contexts';
import { Timer } from '../features/app/vehicleCharging/components/details/vehicleDetails/ChargingMode/vehicleChargingConfigurationTypes';

const VehicleDetailsProvider = ({ children }: PropsWithChildren) => {
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [sendingChargingConfigFailed, setSendingChargingConfigFailed] = useState(false);
    const [sendingChargingConfigSucceeded, setSendingChargingConfigSucceeded] = useState(false);
    const [changedChargingTab, setChangedChargingTab] = useState(false);
    const [immediateChargingChanges, setImmediateChargingChanges] = useState({ chargeUntil: 100, climateMode: false });
    const [timers, setTimers] = useState<Timer[] | []>([]);
    const [unsavedTimers, setUnsavedTimers] = useState<Timer[] | []>([]);

    const updateTimerEnabledStatus = (timerId: string, value: boolean) => {
        const updatedTimers = unsavedTimers.map(timer => {
            if (timer.id === timerId) {
                timer.enabled = value;
            }
            return timer;
        });
        setUnsavedTimers(updatedTimers);
        setHasUnsavedChanges(hasTimersChanged(updatedTimers));
    };

    const hasTimersChanged = (updatedTimers: Timer[]): boolean => {
        return timers.some(timer => {
            return !updatedTimers.some(
                updatedTimer => timer.id === updatedTimer.id && timer.enabled === updatedTimer.enabled
            );
        });
    };

    const resetContext = () => {
        setHasUnsavedChanges(false);
        setSendingChargingConfigFailed(false);
        setSendingChargingConfigSucceeded(false);
        setChangedChargingTab(false);
        setImmediateChargingChanges({ chargeUntil: 100, climateMode: false });
        setTimers([]);
        setUnsavedTimers([]);
    };

    return (
        <VehicleDetailsContext.Provider
            value={{
                hasUnsavedChanges,
                setHasUnsavedChanges,
                sendingChargingConfigFailed,
                setSendingChargingConfigFailed,
                sendingChargingConfigSucceeded,
                setSendingChargingConfigSucceeded,
                changedChargingTab,
                setChangedChargingTab,
                immediateChargingChanges,
                setImmediateChargingChanges,
                timers,
                setTimers,
                unsavedTimers,
                setUnsavedTimers,
                updateTimerEnabledStatus,
                resetContext,
            }}
        >
            {children}
        </VehicleDetailsContext.Provider>
    );
};

export default VehicleDetailsProvider;
