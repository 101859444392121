import { AccessToken } from '../configuration';
import { isDevEnvironment } from './utils';
import { getLocalAccessToken } from './Api';
import { config } from '../config';

const chargingStateEndpoint = `${config.backend.OTA_CHARGING_STATE_SERVICE}/assets`;

const generateHeaders = async (accessToken: AccessToken, contentType = 'application/json') => {
    const bearerToken = isDevEnvironment() ? await getLocalAccessToken() : accessToken;
    return {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': contentType,
    };
};

export const fetchVehicleChargingConfigurations = async (
    accessToken: AccessToken,
    assetId: string
): Promise<Response> => {
    const endpoint = `${chargingStateEndpoint}/${assetId}/charging-configurations`;
    const headers = await generateHeaders(accessToken);
    const response = await fetch(endpoint, {
        headers,
        method: 'GET',
    });
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return response;
};

export const postImmediateConfigurations = async (
    accessToken: AccessToken,
    assetId: string,
    body: string
): Promise<Response> => {
    const immediateConfigurationsEndpoint = `${chargingStateEndpoint}/${assetId}/charging-configurations/immediate`;
    const headers = await generateHeaders(accessToken);

    const response = await fetch(immediateConfigurationsEndpoint, {
        headers,
        method: 'POST',
        body,
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return response;
};

export const postTimerConfigurations = async (
    accessToken: AccessToken,
    assetId: string,
    body: string
): Promise<Response> => {
    const timerConfigurationsEndpoint = `${chargingStateEndpoint}/${assetId}/charging-configurations/timers`;
    const headers = await generateHeaders(accessToken);

    const response = await fetch(timerConfigurationsEndpoint, {
        headers,
        method: 'POST',
        body,
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return response;
};

export const patchTimers = async (accessToken: AccessToken, assetId: string, body: string): Promise<Response> => {
    const url = `${chargingStateEndpoint}/${assetId}/charging-configurations/timers`;
    const headers = await generateHeaders(accessToken);
    const response = await fetch(url, {
        headers,
        method: 'PATCH',
        body,
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return response;
};
