import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import ImmediateCharging from './ImmediateCharging';
import TimerCharging from './TimerCharging/TimerCharging';
import { VehicleChargingConfiguration } from './vehicleChargingConfigurationTypes';

export interface ChargingTab {
    id: string;
    label: ReactElement;
    content: ReactElement;
}

export const createChargingTabs = (
    assetId: string,
    vehicleDetails: VehicleChargingConfiguration | null,
    isReadOnly: boolean
): ChargingTab[] => [
    {
        id: 'immediateTab',
        label: <FormattedMessage id="e4c.vehicle.details.tab.immediateCharging" />,
        content: <ImmediateCharging configuration={vehicleDetails?.immediateConfiguration} isReadOnly={isReadOnly} />,
    },
    {
        id: 'timerTab',
        label: <FormattedMessage id="e4c.vehicle.details.tab.timerCharging" />,
        content: <TimerCharging assetId={assetId} timers={vehicleDetails?.timers} isReadOnly={isReadOnly} />,
    },
];
