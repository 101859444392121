import isNil from 'lodash/fp/isNil';
import { ProductVersion } from '../../common/vehicles/productVersion';
import ChargingStatus from '../models/enums/ChargingStatus';
import VehicleModel from '../models/enums/VehicleModel';
import VehicleType from '../../common/vehicles/VehicleType';
import { VehicleChargingData } from '../models/VehicleChargingData';

const DEFAULT_STATE_OF_CHARGE_VALUE = -1;

export const hasStateOfChargeAvailable = (stateOfCharge: number | undefined): boolean => {
    return stateOfCharge !== DEFAULT_STATE_OF_CHARGE_VALUE;
};

export const hasRemainingCapacityKWhAvailable = (
    assetInfo: VehicleChargingData['assetInfo'],
    productVersion: ProductVersion
): boolean => {
    return assetInfo.vehicleModel !== VehicleModel.ETGE && productVersion === ProductVersion.EMANAGER_M;
};

export const isCharging = (chargingStatus: ChargingStatus | undefined): boolean => {
    return chargingStatus === ChargingStatus.CHARGING || chargingStatus === ChargingStatus.CHARGING_LONGPRESS;
};

export const isValid = (productVersion: ProductVersion): boolean => {
    return productVersion !== ProductVersion.NOT_BOOKED;
};

export const isChargingConfigurable = (assetInfo: VehicleChargingData['assetInfo']): boolean => {
    return assetInfo.vehicleModel !== VehicleModel.ETGE;
};

export const getModifiedAt = (assetInfo: VehicleChargingData['assetInfo']): string => {
    return assetInfo.modifiedAt;
};

export const mapEnumVehicleType = (vehicleType: string): VehicleType => {
    switch (vehicleType) {
        case 'VAN':
            return VehicleType.VAN;
        case 'BUS':
            return VehicleType.BUS;
        case 'TRUCK':
            return VehicleType.TRUCK;
        default:
            return VehicleType.BUS;
    }
};

export const mapEnumVehicleModel = (vehicleModel: string): VehicleModel => {
    switch (vehicleModel) {
        case 'ETGE':
            return VehicleModel.ETGE;
        case 'TRUE':
            return VehicleModel.TRUE;
        case 'ELSA_E_12':
        case 'ELSA_E_18':
            return VehicleModel.ELSA;
        case 'UNKNOWN': // This is a temporary fix for vehicles that are already in DB but has unknown type. The vehicles coming from production will always be LION types.
        case 'LION_CITY_E_10':
        case 'LION_CITY_E_12':
        case 'LION_CITY_E_18':
            return VehicleModel.E4C;
        default:
            return VehicleModel.E4C;
    }
};

export const roundStateOfCharge = (stateOfCharge?: number | null): number => {
    return !isNil(stateOfCharge) ? Math.round(stateOfCharge) : DEFAULT_STATE_OF_CHARGE_VALUE;
};

export const roundTemperature = (temperature?: number | null): number | undefined => {
    return !isNil(temperature) ? Math.round(temperature) : undefined;
};
